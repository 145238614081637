@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  min-height: 100vh;
}

/* -----------------nav --------------------- */
/* Mobile-first CSS for responsiveness */
/* Base styles for mobile-first design */


.site-logo {
  width: 100%;
  height: auto;
  max-width: 150px;
}

.nav-nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.nav-nav-menu-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  justify-content: space-between;
  justify-items: center;

}

.menu-item {
  margin: 10px 0;
}
.bnenew{
  display: flex;
}
.nav-item {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  display: block;
  /* padding: 10px; */
}

.nav-button{
  color: #000000;
  background: #f5f5f5;;
  font-size: 12px;
  font-family: sans-serif;
  width: 133px;
  height: 20px;
  border-radius:3px ;
  text-align: center;
}

.nav-button-2 button
{
  color: white;
  background: #0206ff;
  border-radius: 10px;
}
.nav-button-2 button:hover{
  background: black;
}
.nav-item:hover{
  color: #007bff;
}



.nav-button , .nav-button-1:hover{
  color: #007bff;

}


/* Adjust navbar right section */
.navbar-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Media queries for tablet and larger screens */
@media (min-width: 300px) {
  .site-logo {
    max-width: 120px;
  }

  .nav-nav-menu-list {
    flex-direction: row;
    justify-content: space-around;
  }

  .menu-item {
    margin: 6px 15px;
  }

  .navbar-right {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .nav-button,
  .nav-button-1,
  .nav-button-2 {
    margin-left: 20px;
  }
}

.c-btn p{
  color: #000;
  padding: 0px !important;
}

/* Media queries for desktop screens */
@media (min-width: 1024px) {
  .Ab1 {
    padding: 30px 0;
  }

  .site-logo {
    max-width: 223px;
    width: 268px;
  }

  .nav-nav-menu-list {
    margin-left: 40px;
  }

  .menu-item {
    margin: 0 15px;
  }

  .navbar-right {
    justify-content: flex-end;
  }
}



/* -----------------nav --------------------- */

/* ===============marqueSection============================== *
/
/* Base Section Styling */


.marquee {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  /* height: 35px; */
}

.marquee p {
  display: inline-block;
  padding-left: 100%;
  font-size: 16px;
  color: white;
  animation: marquee 32s linear infinite;
}

/* Keyframes for Marquee Animation */
@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

/* Responsive Typography */
@media (max-width: 768px) {
  .marquee p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .marquee p {
    font-size: 12px;
  }
}




/* ====================== */





/* -----------------Bannner----------------------- */
/* Base Section Styling */
.banner-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-container {
  max-width: 1200px; /* Max width for container */
  margin: 0 auto; /* Center container */
}

.banner-column {
  width: 100%;
}

/* Image Styling */
.banner-widget-image img {
  width: 100%; /* Make the image responsive */
  max-width: 100%; /* Prevent image from exceeding its container */
  height: auto; /* Maintain aspect ratio */
  position: relative;
}

.banner-widget-image h1{
  position: absolute;
  top: 230px;
}

.banner-widget-image p{
  position: absolute;
  top: 540px;
}
.banner-button-text{
  position: absolute;
  top:720px;
}

/* Heading Styling */
.banner-widget-heading .banner-heading-title {
  font-size: 2em; /* Adjust size as needed */
  color: #333; /* Change text color */
  margin: 10px 0;
  text-align: center; /* Center-align heading */
}

/* Text Editor Styling */
.banner-widget-text-editor {
  text-align: center; /* Center-align text */
  font-size: 1em; /* Adjust size as needed */
  color: #555; /* Change text color */
  margin: 10px 0;
}

/* Button Styling */
.banner-button-text
{
  display: inline-block;
  background-color: #1439f3;
  padding: 10px 10px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  margin-top: -10px;
}
.banner-widget-heading , .banner-widget-text-editor, .banner-button-text{
  position: absolute;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .banner-widget-heading .banner-heading-title {
    font-size: 1.5em; /* Adjust heading size for smaller screens */
  }

  .banner-widget-text-editor {
    font-size: 0.9em; /* Adjust text size for smaller screens */
  }

  .banner-button {
    padding: 8px 16px; /* Adjust button padding */
  }
}

@media (max-width: 480px) {
  .banner-widget-heading .banner-heading-title {
    font-size: 1.2em; /* Further adjust heading size */
  }

  .banner-widget-text-editor {
    font-size: 0.8em; /* Further adjust text size */
  }
}

/* ====================slick==================
 */
 .slick{
  padding-left: 20%;
  padding-right: 20%;
 }

 
/* =================element========= */
.service .btn-primary, .services .btn-primary {
  background-color: #007bff; /* Custom blue color */
  border-color: #007bff;
  color: white;
  border-radius: 30px;
}
.swiper-pagination {
  margin-bottom: -15px;
  color: #000;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: 'prev'; /* Set your custom content */
  font-size: 10px !important; /* Override with !important */
  color: rgb(100, 92, 216); /* You can adjust the color too */
}

/* ==========elements css==== */

.company-info{
  margin-top: 80px;
  margin-bottom: 70px;
  text-align: center;
}

.company-info .brands{
  font-size: 48px;
    font-weight: bold;
    text-align: center;
   

}

.btn1 {
  text-align: center;
  margin-top: 50px ;
  


}
 .btn43 button{
  color: #0e80fa; background: rgb(150, 213, 247);
  font-weight: bold;
  font-size:100%;
  width: 177px;
  height: 40px;
  

 }

/* ===========App.css ImageSection=========== */
/* App.css
/* App.css */
body {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}

.card-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.card {
  background-color: #fff;
  /* border-radius: 1px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  width: 100%;
  text-align: center;
  padding: 10px;
}

.card-img {
  max-width: 100%;
  /* border-radius: 8px; */
}

.card-content {
  margin-top: 10px;
}


/* =============HealthcareSection============= */
/* App.css */
.Health-section {
  padding: 20px;
  background-color: #fff; /* Change as needed */
  text-align: center;
}

.Health-container {
  max-width: 1140px;
  margin: 0 auto;
}

.Health-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Health-widget-container {
  padding: 20px;
}

.Health-heading-title {
  font-size: 50px;
  font-weight: bold;
  /* margin: 0px 70px; */
  color: #333;
  font-family: Arial, sans-serif;
  text-align: left;
  text-align: center;
  padding: 0px 146px;
}




/* ===================servicesSection================= */
.services-section {
  padding: 20px;
  background-color: #fff;
}
.services-container {
  display: flex;
  flex-wrap: wrap;
}
.services-column {
  flex: 0 0 25%;
  padding: 15px;
}
.services-column  .services-cta__title:hover
{
  color: #eb821f;
}
.services-cta__content {
  text-align: center;
  padding: 10px;
}
.services-cta__title {
  font-size: 20px;
  margin: 10px 0;
  color: #000;
}
.services-cta__description {
  font-size: 16px;
}
.services-button {
  display: inline-block;
  background-color: #000203;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 15px;
}
.services-cta {
  text-decoration: none; /* Remove underline from the service CTA links */
  color: inherit; /* Inherit color from parent element */
  border: 2px solid transparent; /* Default border */
  transition: border 0.4s ease; /* Smooth transition for the border */
  padding: 20px; /* Add some padding */
  border-radius: 40px; /* Optional: rounded corners */
}
/* Hover effect for the card */
.services-cta:hover {
  border: 10px solid blue; /* Change border color on hover */
}
.services-cta:hover .services-cta__title {
  color: blue; /* Change title color to blue on hover */
}
.services-button { 
  color: white; /* Set button text color */
  position: relative; /* Position for the pseudo-element */
  overflow: hidden; /* Prevents the underline from overflowing */
  text-decoration: none; /* Removes the default underline */
  /* Set default text color */
}
.services-button::after {
  content: ""; /* Pseudo-element to create the underline */
  position: absolute; /* Position relative to the button */
  left: 50%; /* Start from the middle */
  bottom: 0; /* Position at the bottom of the button */
  width: 0; /* Initial width of the underline */
  height: 4px; /* Height of the underline */
  background-color: rgb(9, 9, 10); /* Color of the underline */
  transition: left 0.1s ease, width 0.1s ease; /* Transition effect */
}
.services-button:hover::after {
  left: 0; /* Move the underline to the left */
  width: 100%; /* Expand the width to full */
}

/* =========================NumbersSection ================ */
/* App.css */
.number-section {
  padding: 20px;
  background-color: #fff;
}

.number-container {
  display: flex;
  justify-content: center;
}

.number-widget-container {
  padding: 0px;
}

.number-heading-title {
  font-size: 20px;
  margin: 10px auto; /* Centers the block */
  text-align: center !important; /* Centers the text */
  background: #b5d9ff;
  width: 50%; /* Adjust this width as needed */
}


.number-heading-title-h2{
  font-size: 40px;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;
  text-align: center;

}

.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}



/* ============================EnquiriesSection=========================== */

.Enquiries-section {
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
}

.Enquiries-container {
  display: flex;
  width: 100%;
  max-width: 1200px; /* Adjust based on your layout */
  margin: auto;
}

.Enquiries-column {
  flex: 1; /* Each column will take up equal space */
  padding: 10px; /* Adjust space between columns */
  box-sizing: border-box; /* Include padding in width calculation */
}

.Enquiries-widget-wrap {
  background-color: #fff; /* Background color for the widget */
  border-radius: 50px; /* Rounded corners for better aesthetics */
  padding: 20px; /* Inner padding */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
  height: 100%;
}

.Enquiries-widget-heading {
  margin-bottom: 20px; /* Space between headings */
  font-weight: bold; /* Bold headings */
}
.Enquiries-widget-heading {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px; 
}
.Enquires-widget-line{
  display: flex;
  justify-content: space-between;
}
.Enquiries-heading-title {
  font-size: 20px;
  margin: 10px 0;
  text-align: center; 
  padding: 10px; 
  border-radius: 0px; 
}
.Enquiries-image-box-content-1{
  margin-top: 10px;
}
.content-1{
  background: rgb(196, 226, 249)
}
.content-2{
  background: rgb(201, 247, 201);
}
.Enquiries-image-box-wrapper {
  margin-bottom: 20px; 
}
.Enquiries-button {
  display: block; /* Make it a block-level element */
  margin: 0 auto; /* Automatically adjust margins */
  color: #000000;
  padding: 10px 15px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
}
.Enquiries-button:hover {
    color: #0206ff;
  }


/* ==============SocialMediaSection================ */

.social-section {
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
}


.social-container {
  display: flex;
  width: 100%;
  max-width: 1200px; /* Adjust based on your layout */
  margin: auto;
}

.social-column {
  flex: 1; /* Each column will take up equal space */
  padding: 10px; /* Adjust space between columns */
  box-sizing: border-box; /* Include padding in width calculation */
}

.social-widget-wrap {
  background-color: #fff; /* Background color for the widget */
  border-radius: 50px; /* Rounded corners for better aesthetics */
  padding: 20px; /* Inner padding */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
  height: 100%;
}


.social-widget-heading {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px; 
  font-weight: bold; 
  justify-content: space-between;
  color:#fff;
}

.social-heading-title {
  font-size: 20px;
  margin: 10px 0;
  text-align: center; 
  padding: 10px; 
  border-radius: 0; 
}

.social-image-box-content-1 {
  margin-top: 10px;
}

.content-1 {
  background: rgb(12, 12, 12);
}



.social-image-box-wrapper {
  margin-bottom: 20px; 
}

.social-button {
  display: block; /* Make it a block-level element */
  margin: 0 auto; /* Automatically adjust margins */
  color: #fff; /* Change to #000 for consistency */
  /* padding: 10px 15px; */
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  transition: color 0.3s; /* Smooth transition for hover effect */
}

.social-button:hover {
  color: #0206ff; /* Change color on hover */
}

/* Additional styles for responsiveness (optional) */
@media (max-width: 768px) {
  .social-container {
    flex-direction: column; /* Stack columns on small screens */
  }

  .social-column {
    padding: 10px 0; /* Reduce padding between stacked columns */
  }
}

/* ====================Recent==================== */
.center-bold-text {
  text-align: center; /* Centers the text */
  font-weight: bold; /* Makes the text bold */
  font-size: 45px; /* Sets the font size */
  font-family: "Inter Tight", sans-serif; /* Specifies the font family */
}

/* ====================imagescroll============= */
.recent-projects {
  padding: 40px 20px; /* Padding for the section */
  text-align: center; /* Center align text */
  background-color: #ffffff; /* Light background color for contrast */
}

.section-title {
  font-size: 2em; /* Large title */
  margin-bottom: 20px; /* Margin below the title */
  color: #000; /* Dark text color */
}

.projects-container {
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Center items horizontally */
  flex-wrap: wrap; /* Allow wrapping to next line if necessary */
}

.project-card {
  position: relative; /* Positioning for child absolute positioning */
  background-color: white; /* Card background color */
  border: 1px solid #ddd; /* Light border around the card */
  border-radius: 8px; /* Rounded corners */
  margin: 10px; /* Space between cards */
  width: calc(25% - 20px); /* Set width to 25% of the container */
  max-width: 300px; /* Max width for responsiveness */
  height: 400px; /* Define the height of the card */
  overflow: hidden; /* Hide overflow */
  transition: transform 5s ease-in-out; /* Smooth hover effect */
}

.project-card:hover {
  transform: scale(1.05); /* Slightly scale up on hover */
}

.image-container {
  position: relative; /* Relative position for scrolling */
  width: 100%; /* Full width of the card */
  height: 100%; /* Full height of the card */
  overflow: hidden; /* Hide overflowing image */
}

.image-container img {
  position: absolute; /* Make image scrollable */
  top: 0;
  left: 0;
  width: 100%; /* Make the image responsive to the container */
  height: auto; /* Keep aspect ratio */
  transition: transform 5s ease; /* Smooth scroll transition */
}

/* Scroll effect on hover */
.project-card:hover .image-container img {
  transform: translateY(-66%); /* Scroll the image upwards on hover */
}

.project-content {
  padding: 20px; /* Padding inside the card for content */
  text-align: center; /* Center-align content */
  position: relative; /* To ensure text is on top */
  z-index: 1; /* Higher z-index to be above the image */
}


/* ==================================================================== */
/* =====================clinic=================== */
.clinic-section {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.clinic-top-section {
  width: 100%;
  background-color: #fff; /* Adjust background color as needed */
}

.clinic-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}

.clinic-column {
  width: 100%;
}

.clinic-widget-wrap {
  display: flex;
  flex-direction: column;
}

.clinic-widget-heading {
  margin: 20px 0; /* Adjust margin as needed */
}

.clinic-heading-title {
  font-size: 2.5em; /* Adjust font size as needed */
  text-align: center;
  font-weight: bold;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/* ===========================videosection==================== */
.video 
{
  padding: 20px;
 

}
.video iframe{
  border-radius: 20px;

}

/* ====================imagescroll============= */
.recent-projects {
  padding: 40px 20px; /* Padding for the section */
  text-align: center; /* Center align text */
  background-color: #ffffff; /* Light background color for contrast */
}

.section-title {
  font-size: 2em; /* Large title */
  margin-bottom: 20px; /* Margin below the title */
  color: #000; /* Dark text color */
}

.projects-container {
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Center items horizontally */
  flex-wrap: wrap; /* Allow wrapping to next line if necessary */
}

.project-card {
  position: relative; /* Positioning for child absolute positioning */
  background-color: white; /* Card background color */
  border: 1px solid #ddd; /* Light border around the card */
  border-radius: 8px; /* Rounded corners */
  margin: 10px; /* Space between cards */
  width: calc(25% - 20px); /* Set width to 25% of the container */
  max-width: 300px; /* Max width for responsiveness */
  height: 400px; /* Define the height of the card */
  overflow: hidden; /* Hide overflow */
  transition: transform 5s ease-in-out; /* Smooth hover effect */
}

.project-card:hover {
  transform: scale(1.05); /* Slightly scale up on hover */
}

.image-container {
  position: relative; /* Relative position for scrolling */
  width: 100%; /* Full width of the card */
  height: 100%; /* Full height of the card */
  overflow: hidden; /* Hide overflowing image */
}

.image-container img {
  position: absolute; /* Make image scrollable */
  top: 0;
  left: 0;
  width: 100%; /* Make the image responsive to the container */
  height: auto; /* Keep aspect ratio */
  transition: transform 5s ease; /* Smooth scroll transition */
}

/* Scroll effect on hover */
.project-card:hover .image-container img {
  transform: translateY(-66%); /* Scroll the image upwards on hover */
}

.project-content {
  padding: 20px; /* Padding inside the card for content */
  text-align: center; /* Center-align content */
  position: relative; /* To ensure text is on top */
  z-index: 1; /* Higher z-index to be above the image */
}
/* =================section================== */

.sec-section {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
}

.sec-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.sec-column {
  width: 100%;
}

.sec-widget-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sec-widget-heading {
  text-align: center;
  animation: fadeInUp 0.6s ease-in-out;
}

.sec-heading-title {
  font-size: 2rem;
  font-weight: bold;
}

@keyframes fadeInUp {
  from {
      transform: translateY(20px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}
/* =======part-2=============== */
.sec-1-section {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.sec-1-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}

.sec-1-column {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sec-1-widget-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
}

.premium-image-scroll-section {
  position: relative;
}

.premium-image-scroll-container {
  overflow: hidden;
  height: 300px; /* Adjust height as needed */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.premium-image-scroll-vertical {
  transition: transform 3s ease;
}

.premium-image-scroll-container:hover .premium-image-scroll-vertical {
  transform: translateY(-35%); /* Adjust hover effect as needed */
}

.premium-image-scroll-link {
  display: block;
}

.premium-image-scroll-img {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}


/* ======================TabSection=========================== */
.healthcare-section-container {
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
}



.Heal-heading-title {
  margin-top: 38px;
  margin-bottom: 30px;
  padding: 20px 30px;
  font-size: 12px;
  font-family:sans-serif;
}
.Heal-heading-title button {

  background: #000000;
  color: #eb821f;
}
.Heal-heading-title-1 {
  font-family: sans-serif;
  font-weight: bold;
  color: #000000;
  font-size: 48px;
  padding:10px 20px;

}

.Heal-widget-container p {
  font-family: Helvetica;
  font-size: 20px;
  margin-bottom: 20px;
  padding:10px 20px;
}
.Heal-button-wrapper {
  margin-top: 20px;
}

.Heal-button {
  color: white;
  padding-left: 10px;
}


.btn-get{
  background: #007bff;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.Heal-col-50 {
  width: 50%;
}

.Heal-background {
  background-color: #f9f9f9;
}

/* .Heal-spacer-inner {
  height: 50px;
} */

/* =======================singlevideo==================== */
.SingleVideo{
  border-radius: 10px;
  margin-top: 80px;
  padding: -20px 20px;
}
.SingleVideo iframe {
  border-radius: 20px;
}

/* ==============================InstaGal============= */
/* InstagramGallery.css */
/* Container holding the cards */
.Gal-container {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background-color: #fffdfd;
  background: white;
  overflow: hidden;
}

.Gal {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgb(239 0 0 / 20%);
  width: 19%;
  height: 250px;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  animation: slideInUp 0.6s ease both infinite;
  animation-direction: alternate;
}
.Gal img{
  width: 100%;
  height: 250px;
}

.context-gal{
  font-size: 44px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  padding-left: 430px;
  margin: 0px 0px 30px 30px ;

}

/* =====================ContLog============================== 

/* ================================================================ */


body {
  background: #e9e9e9;
  color: #666;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-module {
  position: relative;
  background: #ffffff;
  /* max-width: 320px; */
  width: 100%;
  border-top: 5px solid #33b5e5;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  padding: 20px;
}

.toggle {
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  background: #33b5e5;
  width: 30px;
  height: 30px;
  margin: -5px 0 0;
  color: #ffffff;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
}

.tooltip {
  position: absolute;
  top: 5px;
  right: -65px;
  display: block;
  background: rgba(0, 0, 0, 0.6);
  width: auto;
  padding: 5px;
  font-size: 10px;
  line-height: 1;
  text-transform: uppercase;
}

.form {
  padding: 40px;
  transition: all 0.3s ease;
  border: 1px solid;
}

.form.active {
  display: block;
}

h2 {
  margin: 0 0 20px;
  color: #33b5e5;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
}

input {
  outline: none;
  display: block;
  width: 100%;
  border: 1px solid darken(#ffffff, 15%);
  margin: 20px 20px 20px;
  padding: 0px 0px;
  box-sizing: border-box;
  font-weight: 400;
  transition: all 0.3s ease; 

}

input:focus {
  border: 1px solid #33b5e5;
  color: #333;
}

button {
  cursor: pointer;
  background: #33b5e5;
  width: 100%;
  border: 0;
  padding: 10px 15px;
  color: #ffffff;
  transition: all 0.3s ease;
}

button:hover {
  background: darken(#33b5e5, 15%);
}

.cta {
  background: darken(#ffffff, 5%);
  width: 100%;
  padding: 15px 40px;
  box-sizing: border-box;    
  color: #666;
  font-size: 12px;
  text-align: center;
  margin-top: -264px;
}
@media(max-width:992px){
  .cta {
   
    margin-top: 0px;
  }
  .Gal-container{
    display: block !important;
  }
  .Gal{
    width: auto !important;
    margin: 20px!important;
  }
  .premium-image-scroll-container {
    height: auto !important;
   
  }
}

.cta a {
  color: #333;
  text-decoration: none;
}

/* .crtacc {
  height: 690px;

} */

.crtacc input{
  margin-bottom:10px ;
  padding: 8px;
}
.crtacc h1{
  font-size: 20px;
}

.lgn{
  height: 200px;
}
.lgn input{
  margin-bottom:30px ;
}
.lgn h1{
  font-size: 20px;
}
.mrk{
  color: white;
  background: #000;
  padding: 20px 10px;
}

.sch span{
  font-size: 20px;
  font-weight: bold;
  padding:0px 10px;
}
.bne
{
  width: 100%;
  background: #bdddff;
  padding: 20px 10px;
}
.bnenew
{
  width: 100%;
  background: #bdddff;
  padding: 20px 10px;
}
.contact-section{
  margin: 0;
  padding: 0;
}
.lst{
  margin-top: 30px;
  
  
}
.lst span{
  padding: 10px 20px;
  border-radius: 50%;
  color: #fbfbff;
  background-color:pink;
  justify-content: space-between;
}
.ftt{
  margin-top:2px;
  padding: 40px 50px;

  background-image: url('assets/images/footerbanner.png'); 
  background-size: cover; 
  background-position: center;
  background-attachment: fixed; 
  background-repeat: no-repeat;
  height: 100%; 
  width: 100%; 
}
.ftt button{
  margin-top: 10px;
  color: white;
  background: rgb(251, 164, 178);
  width: 97px;
  height: 34px;
  font-size: 10px;
  border-radius: 20px;

}
.ftt {
  color: white;
}





:root {
  --mainColor: #29335c;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #eee;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  /* padding: 0 2rem; */
  background-color: #fff;
  color: var(--textColor);
  border-bottom: 1px solid #0000001a;
}

.marquee-widget-wrap{
  background: #020211;
  
}

.marquee p{
  padding: 5px;
}

nav div {
  margin: 0 2rem;
  color: var(--textColor);
  text-decoration: none;
}

nav div:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.head-nav ul{
  list-style: none;
  display: flex;
  padding-left: 0px;
  margin-left: 0px;
  align-content: space-between;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@media (max-width: 992px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }
  .ftt{
    padding-top: 20px!important;
    padding: 10px!important;
  }
  .sce3 h1{
    width: 100%!important;
    font-size: 20px !important;
    margin: 10px 0px 10px 3px;
    /* font-weight: bold; */
    text-align: center;
  }
  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: transform 1s ease-in-out;
    transform: translateY(-100vh);
  }

  header .responsive_nav {
    transform: translateY(0);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav div{
    font-size: 1.5rem;
  }
  .Health-heading-title {
    padding: 0px 78px;
  }
}

.contact-bt{
  padding: 10px 20px;
  background: #020211;
  color: #fff;
  border-radius: 10px;
}

.desk-header{
  display: block;
}

.mobile-header{
  display: none;
}

.video-flex{
  display: flex;
}

.service-section{
  display: flex;
  justify-content: around;
  margin-bottom: 10px;
}
.service-section button{
 background-color: #000!important;
  color: #fff;
}
.service-section2 button{
 background-color: #000!important;
  color: #fff;
}

.service-section2{
  display: flex;
  justify-content: around;
  margin-bottom: 20px;
}

.Heal-spacer-inner img{
  width: 100%;
 }

 .banner-widget-image h1 {
  width: 40%;
  font-size: 300%;
  font-weight: 700;
  }

@media (max-width:992px){
  .vid iframe{
    width: 200px;
  }

  .desk-header{
    display: none;
  }

  .mobile-header{
    display: block;
  }

  .dropdown-menu{
    width: 375px;
    left: -315px;
}
.newbutton{
  font-size: 37px !important;
}
.dropdown-menu.show{
  display: block!important;
    /* padding-left: 40px !important; */
}

.video-flex{
  display: block;
}

.context-gal {
  padding-left: 0px;

}

.Enquiries-container {
  display: block;
}

.service-section, .service-section2{
  display: block;
}

.service-section button , .service-section2 button{
  width: 45%;
  margin-bottom: 10px;
}



.healthcare-section-container {
  display: block;
}

.tabbable .nav-item {
  padding: 0px;
}

.tabbable .nav-tabs {
  display: flex;
}

.banner-widget-image h1 {
  top: 138px;
  width: 40%;
    font-size: 20px;
    font-weight: 700;
}
.banner-widget-image p{
  top:260px
}
.banner-button-text {
  margin-top: -275px;
  padding: 7px 10px;
}
.slick h1{
  margin-top: 105px;
}
.flag-slide img{
  width: 100%;
}

}

.flag-slide img{
   width: 100px;
    height: auto;
}

.banner-heading-title p{
   width: 50%;
    font-size: 150%;
    font-weight: 500;
    

   
    

}

/* =============service==================== */
.sce{
  text-align: center;
  font-size: 55px;
  font-weight: 700;
  font-family: sans-serif;
}
.sc1{
  text-align: center;
  font-size: 24px;
  color: grey;
}
.sce-bnnr img{
  width: 100%;
  height: 90%;
}
.sce1 p{
  text-align: center;
  font-size: 25px;
  padding: 35px 40px;
}
.sec2 h1{
  padding: 0px 0 0px 77px;
  margin: 0px 0 19px 0px;
  font-size: 41px;
  width: 38%;
  font-weight: bold;
}
.sce-content p {
    font-size: 20px;
    color: grey;
    margin-top: 10px;
}
.service-banner{
  width: 100%;
  height: 100%;
}
.sce3 h1{
  /* width: 51%; */
  font-size: 41px;
  margin: 10px 0px 10px 3px;
  font-weight: bold;
}
.sce3 button{
  background-color: #000!important;
}

sce3 p{
  font-size: 16px;
}
sce3 Button{
  width: 124px;
}

.form-module button
{
  background-color: #000!important;
  color: #fff;
}
.soc {
  display: inline-block;
  margin: 0 5px;
  padding: 10px;
  border-radius: 50%;
  color: white; /* Icon color */
  /* font-size: 24px; Adjust size as needed */
}
.soc  svg{
  width: 24px;
  height: 24px;
  color: #fff;
}

.soc:nth-child(1) {
  background-color: #00a0f6; /* LinkedIn */
}

.soc:nth-child(2) {
  background-color: #e4405f; /* Instagram */
}

.soc:nth-child(3) {
  background-color: #3b5998; /* Facebook */
}

.soc:nth-child(4) {
  background-color: #1DA1F2; /* X (formerly Twitter) */
}

.soc:nth-child(5) {
  background-color: #FF0000; /* YouTube */
}
.bottomfooter{
  background: #000;
  color: rgb(14 255 212);
  padding: 10px;
}



.carousel1 img {
  height: 400px; /* Set the desired height */
  object-fit: cover; /* Ensures the image covers the height without distorting the aspect ratio */
}

.Harsh button{
  width: 20%;
  margin-top: 50px;
  margin-bottom: 20px;
}
.Harsh p {
  margin-top: 10px;
  font-size: 20px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  width: 90%;
}
.Harsh1{
  background:#000;
  border-radius: 10px;
  padding: 0px 0px 0px 0px;
  margin-top: 80px;
}
.Harsh1 button{
  width: 14%;
  margin-top: 20px;
  /* margin-bottom: 160px; */
  color: #fff;
  background: #000;
}
.Harsh1 p{
  margin-top: 10px;
  font-size: 40px;
  font-family:Arial, Helvetica, sans-serif;
  width: 90%;
  font-weight: bolder;
  color: #fff;
  width: 54%;
  margin-bottom: 40px;

}

.Harsh2{
  margin-top: 80px;
}

.Harsh3 {
  background-image: url("assets/bg-dot-shape.svg");
  background: #edf1f6;
  
}
.Harsh3 h1{
  text-align: center;
  font-weight: bold;
  margin-top: 50px;
}

.Harsh3 p{
  text-align: center;
  padding-left: 79px;
  margin-top: 30px ;
  margin-bottom: 30px;
}
.Harsh3 span{
  color: #fff;
  background: #0206ff;
  padding: 10px 10px 10px 10px;
 
}
.Harsh4{
  margin-top: 47px;
margin-bottom: 40px;
}
.pt1{
  background-image: url("assets/sidebnr.jpg");
}
.pt2 {
  width: 113px;
  border-radius: 3px;
  margin-top: 30px;
  font-weight: bold;
  background: #c8c8c8;
  font-size: medium;
  color:#fff;
}
.services-button a{
  color:white;
}
.sols, .consultation-link {
  text-decoration: none;
  color: inherit; /* Optionally, inherit the color from the parent element */
}
.pt1 h1{
  margin-top: 30px;
  font-weight: bold;
  font-size: 42px;
  color: #fff;
}
.pt1 p {
  margin-top: 30px;
  font-size: 20px;
  color: #fff;
}
.pt3{
  width: 250px;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 20px;
  background: #c8c8c8;;
}
.ttp p{
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  /* width:150% */
}

.frr, .consultation-link{
  text-decoration: none;
  color: inherit;
}
.ttms{
  font-weight: bold;
  font-size: 30px !important;
  font-family: Arial, Helvetica, sans-serif;
}
.newseo a{
  text-decoration: none !important;
  color: white;
}
.ttp h4{
  font-weight: bold;
  font-size: 23px;
}
.pt6{
  margin-top: 50px;
}

.pt5{
  margin-top: 90px;
}
.pt5 h3{
  font-size: 47px;
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif;
}
.pt5 p{
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  color: #555;
}
.dd{
  margin-top: 40px;
}
.pt5 img{
  width: 100%;
  height: 80%;
}
 .ppat{
  background-image: url("assets/sidebr1.avif");
  width: 100%;
  /* height:40%; */
}

.ppat h2 {
 padding: 10px ;
 

}
.ppat p {
 padding: 10px ;
 color: white;
 margin-bottom: 40px ;
 padding-bottom: 40px;

}

.scnt p{
  font-size: 12px ;
  font-family: Arial, Helvetica, sans-serif;
}
.multiple-items-slider {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.slick-slide {
  /* padding: 10px; */
  /* background-color: #f1f1f1; */
  border-radius: 10px;
  margin-right: 10px;
}

.slick-slide img {
  max-width: 100% !important;
  padding: 50px;
  border-radius: 10px;
}
.testnew{
  text-align: center;
  padding-top: 30px;
}
.slick-prev, .slick-next{
  /* top: 16% !important; */
  background-color: black !important;
}

.multiple-items-slider {
  width: 100%;
  overflow: hidden;
}

@media(max-width:992px){
  .multiple-items-slider {
    padding-top: 145px!important;
  }
}

.slick-slide {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-slide img {
  width: 100%;
  height: auto; /* Ensure a consistent height for images */
  object-fit: cover;
}

.slick-slide p {
  margin-top: -30px;
  text-align: center;
}

.slick-track {
  display: flex !important;
  align-items: center;
}

.slick-list {
  overflow: hidden;
  white-space: nowrap;
}


/* Styling for the project card */
.project-card {
  border: 1px solid #eaeaea;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Image container styling */
.image-container {
  width: 100%;
}

.project-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

/* Responsive Styles */
/* For screens less than 768px wide (e.g., mobile), show only 1 image per row */
@media (max-width: 768px) {
  .projects-container {
    grid-template-columns: 1fr; /* 1 column on mobile screens */
  }
}

.banner-widget-video video{
  width: 100%!important;
}
.videobanner .container-fluid{
 padding: 0px!important;

}
.carousel-caption {
  padding-bottom: 8.25rem !important;
}
@media(max-width: 992px){
  .carousel-caption {
    padding-bottom: 4.25rem!important;
  }
}